import BlurredImage from 'components/BlurredImage';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export default function ElectroStimulation() {
    const img1 = require('images/models/man_smoke2.png');
    const img2 = require('images/models/ems_revolution_biojacket.png');

    return (
        <Container
            fluid
            className='fade-in-when-load no-select mt-5'
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Row
                    className='justify-content-center'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        // alignItems: 'center',
                    }}
                >
                    <Col sm='auto' xs='auto' className='text'>
                        <h3>Άσκηση με ηλεκτροδιέγερση</h3>
                        <div
                            style={{
                                display: 'block',
                                width: '300px',
                                height: 'fit-content',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '2vmin',
                            }}
                        >
                            Άσκηση με ηλεκτροδιέγερση των μυών ή EMS άσκηση,
                            είναι ενα σύστημα ενεργοποίησης των μυών, που
                            δημιουργεί την ταυτόχρονη συστολή περισοτερων από
                            350 μύες στο σώμα μας. Με τη συστηματική άσκηση 20
                            λεπτών, μεχρι 2 φορες την εβδομάδα, πετυχαίνουμε τα
                            βέλτιστα αποτελέσματα, που όμοιά τους δεν Θα
                            πετύχουμε ποτέ με την παραδοσιακή γυμναστική στους
                            αντίστοιχους χρόνους και συχνότητα!
                        </div>
                    </Col>
                    <Col sm='auto' xs='auto'>
                        <BlurredImage
                            image={img1}
                            original_size={{ width: 834, height: 1024 }}
                            image_delay={0}
                            texts_delay={0.6}
                            blurhash_only_fade
                            blurHash='U5577QkED4Mce+nMZ$XUHXm*uPlAvKX:YRZz'
                            alt={img1}
                            width='50vmin'
                        />
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col sm='auto' xs='auto'>
                        <BlurredImage
                            image={img2}
                            original_size={{ width: 643, height: 736 }}
                            image_delay={0}
                            texts_delay={0.6}
                            blurhash_only_fade
                            blurHash='U03vBV.mHX+]^no}uhyEGGV?HXM}4TR59[IV'
                            alt={img2}
                            width='50vmin'
                        />
                    </Col>
                    <Col sm='auto' xs='auto' className='text'>
                        <h3 style={{ width: '350px' }}>
                            Η επανάσταση στην ηλεκτροδιέγερση ήρθε και στην
                            Ελλάδα
                        </h3>
                        <div
                            style={{
                                display: 'block',
                                width: '300px',
                                height: 'fit-content',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '2vmin',
                            }}
                        >
                            Οι 3ης γενιάς συσκευές μας, είναι η νεότερη
                            τεχνολογική αναβάθμιση, στην προηγμένη ολοκληρωμένη
                            ηλεκτροδιέγερση. Είναι η πρώτη φορά, που ενα σύστημα
                            ηλεκτροδιεγερσης ξεπερνά ολους τους περιορισμούς και
                            τις απαγορεύσεις, ολων των προηγούμενων συσκευών που
                            κυκλοφορούν στην αγορά. Οι περιορισμοί στην
                            ελευθερία των κινήσεων κατα την ασκηση απο τα
                            καλώδια των συστημάτων ηλεκτροδιέγερσης, η διακοπή
                            της προπονησης απο την τυχαία αποσυνδεση τους και οι
                            αστάβειες των διάφορων τύπων μερικώς ασύρματης
                            σύνδεσης, είναι παρελθόν!
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
}
