import React from 'react';

export default function Title(props: any) {
    return (
        <div
            style={{ marginRight: 'auto', marginLeft: 'auto' }}
            className='sub-title mb-2'
        >
            {props.children}
        </div>
    );
}
