import React from 'react'

export default function Card(props: any) {
    return (
        <div
            className='card text'
            style={{
                height: `${props.height || '100%'}`,
                width: `${props.width || '100%'}`,
            }}
        >
            <div
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '70px',
                }}
            >
                {props.image}
            </div>
            <h3
                className='no-select'
                style={{
                    display: 'flex',
                    textAlign: 'center',
                }}
            >
                {props.title}
            </h3>
            <div
                className='no-select'
                style={{
                    width: 'fit-content',
                    display: 'flex',
                    textAlign: 'left',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                {props.description}
            </div>
        </div>
    );
}
