import React from 'react';

export default function BigText(props: any) {
    return (
        <div
            className='text no-select'
            style={{
                width: props.width,
                fontSize: `${props.size}vmin`,
                textAlign: props.align || 'center',
            }}
        >
            {props.text.split('\n').map((char: any, index: any) => {
                return (
                    <p
                        className='fade-in-when-load'
                        key={index}
                        style={{
                            margin: 0,
                            animationDelay: `${index * 0.2}s`,
                        }}
                    >
                        {char}
                    </p>
                );
            })}
        </div>
    );
}
