import React from 'react';
import PageDescription from 'components/PageDescription';
import { Col, Container, Row } from 'react-bootstrap';
import BlurredImage from 'components/BlurredImage';
import BigText from 'components/BigText';
import Card from 'components/Card';
import { svgs_list } from 'components/SVGList';

export default function Technologies() {
    const techs = [
        {
            name: 'VACU Therm',
            description:
                'Επαναστατικό πατενταρισμένο προϊόν που χρησιμοποιείται στον αθλητισμό, την προετοιμασία, την απώλεια λίπους, την αντι-κυτταρίτιδα, τη φυσική κατάσταση και τη φυσικοθεραπεία.',
            image: require('images/tools/Vacutherm/vacutherm1.png'),
            imagehash: 'UED+;[I:00-94nxa?HIU00xZ~COY-pkWNanO',
            image_original_size: { width: 1146, height: 930 },
            pointers: [
                {
                    x: '35.3%',
                    y: '28.5%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '40.7%',
                    y: '25.3%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '34.2%',
                    y: '49.7%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '48%',
                    y: '30.8%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '60.3%',
                    y: '38.9%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '71.9%',
                    y: '54.2%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '71.8%',
                    y: '71.4%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
            ],
            texts: [
                {
                    x: '0%',
                    y: '2.7%',
                    size: '2',
                    text: 'Αντιμετώπιση τοπικού \n πάχους και κυτταρίτιδας',
                },
                {
                    x: '70%',
                    y: '20.9%',
                    size: '2',
                    text: 'Μείωση πρηξίματος \n και κατακρατίσεων',
                },
                {
                    x: '58%',
                    y: '11.8%',
                    size: '2',
                    text: 'Αύξηση \n καύσης θερμίδων',
                },
                {
                    x: '82%',
                    y: '51.6%',
                    size: '2',
                    text: 'Ενεργοποίηση \n μεταβολισμού',
                },
                {
                    x: '5%',
                    y: '18%',
                    size: '2',
                    text: 'Βελτίωση \n καρδιαγγειακού \n συστήματος',
                },
                {
                    x: '78%',
                    y: '34.9%',
                    size: '2',
                    text: 'Θετική \n επίδραση στην \n οστεοπόρωση',
                },
                {
                    x: '58%',
                    y: '2.8%',
                    size: '2',
                    text: 'Σύσφιξη γοφών & κάτω άκρων',
                },
            ],
            cards: [
                {
                    title: 'Καρδιαγγειακό Σύστημα',
                    description:
                        'Λόγω της αντίδρασης των αιμοφόρων αγγείων στη θερμότητα, η ταχύτητα και η ένταση της ροής του αίματος αυξάνεται.',
                    image: svgs_list.heart,
                },
                {
                    title: 'Μεταβολισμός',
                    description:
                        'Το vacuCABIN είναι ένας εξαιρετικός τρόπος για να χάσετε περιττά κιλά και με την επιτάχυνση του μεταβολισμού, σας επιτρέπει να διατηρήσετε λεπτή σιλουέτα, χωρίς δραστικές δίαιτες και έντονη άσκηση.',
                    image: svgs_list.fire,
                },
                {
                    title: 'Ευκαμψία Μαλακών Στοιχείων Άρθρωσης',
                    description:
                        'Στις επιστημονικές μελέτες, έχει παρατηρηθεί ότι κάθε συνεδρία στο vacuCABIN επιτρέπει μόνιμη διάταση συνδέσμων, αρθρώσεων, τενόντων, της περιτονίας και της αρθρικής μεμβράνης της άρθρωσης.  Επομένως, είναι ιδανικό για χρήση τόσο στον αθλητισμό όσο και στη φυσική αποκατάσταση, που εξασφαλίζει ότι οι ιστοί είναι κατάλληλα προετοιμασμένοι για άσκηση και ότι η τα αποτελέσματα θα είναι μόνιμα.',
                    image: svgs_list.flexible_female,
                },
                {
                    title: 'Αναγέννηση',
                    description:
                        'Η υπέρυθρη ακτινοβολία του vacuCABIN, που διεισδύει σε βάθος έως και 4cm στους μύες, επιταχύνει την αναγέννηση, χάρη στην οποία οι ζωτικές παράμετροι του οργανισμού φτάνουν τις βασικές τους τιμές γρηγορότερα.  Στα αθλητικά κέντρα, οι καμπίνες υπέρυθρων χρησιμοποιούνται  ευρέως στη διαδικασία βιολογικής αναγέννησης μετά την προπόνηση.',
                    image: svgs_list.male_rise,
                },
                {
                    title: 'Τοξίνες',
                    description:
                        'Το κενό είναι ένας απαράμιλλος καυστήρας τοξινών. Σύμφωνα με ειδικούς στο τομέα της απώλειας βάρους, το ανθρώπινο σώμα χρησιμοποιεί λίπος για να διαλύσει τις τοξίνες και έτσι, διώχνοντας τις τοξίνες, αποβάλλουμε και το λίπος. Η απαλλαγή τοξινών και λίπους καθιστούν επίσης δυνατή την διόρθωση της κυτταρίτιδας, που προκαλεί όχι μόνο αισθητικά προβλήματα, αλλά διαταράσσοντας τη ροή στα αγγεία, είναι παράγοντας που συμβάλλει στη δημιουργία πολλών ασθενειών.',
                    image: svgs_list.toxin,
                },
                {
                    title: 'Κυκλοφορία Αίματος',
                    description:
                        'Ο τέλειος συνδυασμός άσκησης και κενού βελτιώνει την κυκλοφορία του αίματος το δέρμα και τον υποδόριο ιστό. Είναι πολύ αποτελεσματικό στις «δύσκολες» περιοχές όπως οι μηροί, οι γλουτοί και η μέση. Η ενέργεια για τους μύες βρίσκεται στα λιποκύτταρα. Κατά τη διάρκεια της άσκησης, το κενό απελευθερώνει τα λιπαρά οξέα από λιπώδη ιστό. Παρέχει επίσης καλύτερη κυκλοφορία του αίματος που επιταχύνει τη μεταφορά στους μυϊκούς ιστούς.',
                    image: svgs_list.blood_presure,
                },
                {
                    title: 'Λαμπτήρες κολλαγόνου LED',
                    description: `Το φως LED χρησιμοποιείται για οπτική λεύκανση και περιποίηση διαφόρων δερματικών προβλημάτων όπως: ακμή, δερματικές αλλεργίες, κηλίδες μελάγχρωσης ή μακροχρόνια επούλωση πληγών. Η λάμπα LED έχει σημαντική επίδραση στην αναγέννηση του δέρματος με σημάδια γήρανσης και στην παραγωγή κολλαγόνου που σχετίζεται με την αύξηση της ηλικίας. Βελτιώνει επίσης την κατάσταση και την ένταση του δέρματος και καταπραΰνει τα ηλιακά εγκαύματα.
                        Η λάμπα λειτουργεί με βάση την αρχή της φωτοδυναμικής θεραπείας, ενεργοποιεί τα κύτταρα των βαθιών στρωμάτων του δέρματος και βελτιώνει τον μεταβολισμό τους. Όταν το δέρμα απορροφά φως, η φωτεινή ενέργεια μετατρέπεται σε μεσοκυττάρια ενέργεια. Σαν αποτέλεσμα, τα μικροαγγεία χαλαρώνουν και δυναμώνουν. Δημιουργείται δηλαδή μία φωτοχημική αντίδραση στο δέρμα - μια ενζυματική αντίδραση.
                        `,
                    image: svgs_list.lamp,
                },
                {
                    title: 'Αρωματοθεραπεία',
                    description: `Η αρωματοθεραπεία είναι μια ολιστική θεραπευτική θεραπεία που χρησιμοποιεί φυσικά φυτά εκχυλίσματα για την προώθηση της υγείας και της ευεξίας. Αρωματικά αιθέρια έλαια χρησιμοποιούνται για τη βελτίωση της υγείας του σώματος, του νου και του πνεύματος.
                        Η αρωματοθεραπεία έχει μια σειρά από οφέλη, μερικά από αυτά είναι η διαχείριση του πόνου, βελτίωση της ποιότητας του ύπνου ,μείωση του άγχους, και της διέγερσης,αντιμετώπιση πονοκεφάλων και ημικρανιών,καταπολεμά βακτήρια, ιούς ή μύκητες και η ενίσχυση του ανοσοποιητικού.
                        `,
                    image: svgs_list.aromatherapy,
                },
                {
                    title: 'Χρωματοθεραπεία',
                    description: `Η χρωματοθεραπεία είναι μια εναλλακτική θεραπεία που χρησιμοποιεί χρώματα σε συχνότητες για τη θεραπεία σωματικών και συναισθηματικών προβλημάτων. Ο στόχος της χρωματοθεραπείας είναι να διορθώσει τη φυσιολογικές και ψυχολογικές ανισορροπίες στο ανθρώπινο σώμα.  Τα χρώματα επηρεάζουν τις ψυχικές μας διεργασίες και μπορούν να αλλάξουν τη διάθεσή μας. Το πολύχρωμο φως που εισέρχεται μέσα από τα μάτια μας παράγει συγκεκριμένες ψυχολογικές επιδράσεις στο ανθρώπινο σώμα. Κάθε χρώμα έχει ένα διαφορετικό αποτέλεσμα μεταξύ των οποίων είναι η βελτίωση διάθεσης, χαλάρωση, ανάκτηση ενέργειας, αναζωογονεί το σώμα και η σωματική θεραπεία.
                    `,
                    image: svgs_list.long_lamp,
                },
                {
                    title: 'Κενό (Yποπίεση)',
                    description: `Το κενό είναι ένας απαράμιλλος καυστήρας τοξινών. Σύμφωνα με ειδικούς στο στον τομέα της απώλειας βάρους, το ανθρώπινο σώμα χρησιμοποιεί λίπος για να διαλύσει τις τοξίνες και έτσι, διώχνοντας τις τοξίνες, αποβάλλουμε και το λίπος. Η απαλλαγή τοξινών και λίπους καθιστούν επίσης δυνατή την διόρθωση της κυτταρίτιδας, που προκαλεί όχι μόνο αισθητικά προβλήματα, αλλά διαταράσσοντας τη ροή στα αγγεία, είναι παράγοντας που συμβάλλει στη δημιουργία πολλών ασθενειών.
                    Ο τέλειος συνδυασμός άσκησης και κενού βελτιώνει την κυκλοφορία του αίματος το δέρμα και τον υποδόριο ιστό. Είναι πολύ αποτελεσματικό στις «δύσκολες» περιοχές όπως οι μηροί, οι γλουτοί και η μέση. Η ενέργεια για τους μύες βρίσκεται στα λιποκύτταρα. Κατά τη διάρκεια της άσκησης, το κενό απελευθερώνει τα λιπαρά οξέα από λιπώδη ιστό. Παρέχει επίσης καλύτερη κυκλοφορία του αίματος που επιταχύνει τη μεταφορά στους μυϊκούς ιστούς.
                    `,
                    image: svgs_list.pressure_meter
                },
            ],
        },
        {
            name: 'HIEMT',
            description: `Το HIEMT είναι ένα πρωτοποριακό μηχάνημα τελευταίας τεχνολογίας που εκπέμπει υψηλής ενέργειας εστιασμένα ηλεκτρομαγνητικά κύματα, σε προσαρμοσμένες για την περίπτωση συχνότητες, τα οποία επιτυγχάνουν ταυτόχρονα καύση λίπους και αύξηση της μυϊκής μάζας. 
                •	Σε κάθε συνεδρία διάρκειας 30 λεπτών πραγματοποιούνται 30.000 μυϊκές συσπάσεις που ενεργοποιούν τον στοχευμένο μυ στο 100%. 
                •	Η θεραπεία είναι ανώδυνη ξεκούραστη και γρήγορη και τα αποτελέσματα είναι άκρως εντυπωσιακά.
                •	Η μεγάλη ένταση των ηλεκτρομαγνητικών κυμάτων το καθιστά ως το πιο αποτελεσματικό μηχάνημα για καύση λίπους στον πλανήτη. 
                •	Μάλιστα, θεωρείται η μόνη τεχνολογία έως τώρα που μπορεί να μειώσει το λίπος και ταυτόχρονα να αυξήσει τη μυϊκή μάζα. 
                •	Η θεραπεία δίνει την αίσθηση ενός έντονου μασάζ χωρίς να πονάει καθόλου και είναι ασφαλής και κατάλληλη για όλες τις ηλικίες και όλα τα φύλλα.
                •	Οι ιδιότητές του έχουν ως αποτέλεσμα την θεραπεία μυϊκών τραυματισμών, προστατοδυνίας και ακράτειας.
                `,
            cards: [
                {
                    title: 'Γλουτούς',
                    description:
                        'Στοχεύοντας την περιοχή των γλουτών επιτυγχάνουμε τοπική αύξηση της μυϊκής μάζας χωρίς να αυξάνουμε των όγκο των μοιρών, πετυχαίνοντας έτσι το αποτέλεσμα που όλες οι γυναίκες επιθυμούμε. Ένα γυμνασμένο και στητό γλουτό χωρίς μεγάλα πόδια.',
                    image: svgs_list.female_glutes,
                },
                {
                    title: 'Κοιλιακούς',
                    description:
                        'Τα εξειδικευμένα πρωτόκολλα του μηχανήματος πραγματοποιούν τοπική καύση λίπους και υπόσχονται επίπεδη και γραμμωμένη κοιλιά.',
                    image: svgs_list.male_abs,
                },
                {
                    title: 'Βραχίονες (Μπράτσα)',
                    description:
                        'Με το μεγάλο αριθμό μυϊκών συσπάσεων απευθείας στην περιοχή των μπράτσων καταφέρνουμε να πετύχουμε τη γράμμωση που τόσο επιθυμούμε αλλά πολύ δύσκολα μπορούμε να πετύχουμε στο γυμναστήριο.',
                    image: svgs_list.male_arm,
                },
                {
                    title: 'Προσαγωγούς',
                    description:
                        'Ένα από τα πιο δύσκολα σημεία εκγύμνασης και σύσφιξης. Με το μηχάνημα καταφέρνουμε να χτίσουμε το μυϊκό σύστημα στους προσαγωγούς με ταυτόχρονη μείωση του τοπικού λίπους στους υποκείμενους ιστούς.',
                    image: svgs_list.female_adductors,
                },
                {
                    title: 'Μηρούς',
                    description:
                        'Ο εφιάλτης κάθε γυναίκας. Το μέρος όπου κυρίως συσσωρεύεται η κυτταρίτιδα. Με το μηχάνημα μπορούμε να επιταχύνουμε τη στοχευμένη εκγύμναση των μηρών και να μειώσουμε το τοπικά συσσωρευμένο λίπος.',
                    image: svgs_list.female_adductors,
                },
                {
                    title: 'Οσφυϊκούς',
                    description:
                        'Με το μεγάλο αριθμό μυϊκών συσπάσεων απευθείας στην περιοχή της μέσης, καταφέρνουμε να πετύχουμε την ενδυνάμωση των μυών και επομένως να βελτιώσουμε τη στήριξη της σπονδυλικής στήλης, απαλείφοντας τους σχετικούς πόνους.',
                    image: svgs_list.male_back,
                },
                {
                    title: 'Πυελική περιοχή',
                    description:
                        'Με το μεγάλο αριθμό μυϊκών συσπάσεων στις ανάλογες συχνότητες και ένταση, απευθείας στην πυελική περιοχή επιτυγχάνουμε την απαλλαγή από προσταδυνία ή ακράτεια, χωρίς να σας φέρνει σε δύσκολη θέση, χωρίς αμηχανία και χωρίς να βγάλετε το ρούχο σας!',
                    image: svgs_list.male_belly,
                },
            ],
            image: require('images/tools/Hiemt/hiemt.png'),
            imagehash: 'UED+;[I:00-94nxa?HIU00xZ~COY-pkWNanO',
            image_original_size: { width: 1146, height: 850 },
            pointers: [
                {
                    x: '44.5%',
                    y: '16.8%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '58%',
                    y: '16.5%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '59.6%',
                    y: '35.2%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '38.9%',
                    y: '42.5%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
                {
                    x: '64.7%',
                    y: '54.8%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
            ],
            texts: [
                {
                    x: '22%',
                    y: '1.5%',
                    size: '2',
                    text: 'Αδυνάτισμα',
                },
                {
                    x: '67%',
                    y: '0.1%',
                    size: '2',
                    text: 'Σύσφιξη και \n αντικυτταρίτιδα',
                },
                {
                    x: '16%',
                    y: '22.8%',
                    size: '2',
                    text: 'Αύξηση \n μυικού όγκου',
                },
                {
                    x: '75%',
                    y: '35.8%',
                    size: '2',
                    text: 'Μείωση \n τοπικού λίπους',
                },
                {
                    x: '70%',
                    y: '17%',
                    size: '2',
                    text: 'Ενεργοποίηση \n μεταβολισμού',
                },
            ],
        },
        {
            name: '8D Dual Laser Maxlipo Slim',
            description: `Το 8D Dual Laser Maxlipo Slim είναι ένα μη επεμβατικό ψυχρό λέιζερ για απώλεια λίπους. Είναι η μόνη μη επεμβατική θεραπεία, εγκεκριμένη από τον FDA, για τη συνολική μείωση της περιφέρειας σώματος και έχει τα καλύτερα αποτελέσματα για συνδυασμένη μείωση περιφέρειας στον κλάδο.
            Eίναι μια θεραπεία για την πλήρη απώλεια λίπους που επιτρέπει στους πελάτες να στοχεύουν περιοχές με επίμονο λίπος και είναι το πρώτο λέιζερ που έχει σχεδιαστεί ειδικά για το περίγραμμα του σώματος. Στοχεύει το υπερβολικό λίπος γαλακτωματοποιώντας λιπώδη ιστό μέσω της χρήσης της τεχνολογίας ψυχρού laser.
            Παρέχει μια εναλλακτική θεραπεία απώλειας λίπους χωρίς τις αρνητικές παρενέργειες που σχετίζονται με χειρουργικές και άλλες διαδικασίες απώλειας βάρους όπως λιποαναρρόφηση, κρυολιπόλυση και περιτύλιξη σώματος.
                •	Διαδικασία ακίνδυνη (χωρίς επίβλεψη)
                •	Δεν εφαρμόζεται απευθείας στο δέρμα
                •	Δεν υπάρχει χρόνος ρύθμισης
                •	Χωρίς μώλωπες, χωρίς πρήξιμο, χωρίς πάγωμα, χωρίς διακοπή λειτουργίας
                •	Μη επεμβατικό
                `,
            description_cards: [
                {
                    title: 'Πώς λειτουργεί;',
                    image: svgs_list.setting,
                    description: `
                        Χρησιμοποιώντας τις 8 ρυθμιζόμενες κεφαλές του για να στοχεύει τα λιποκύτταρα κάτω από την περιοχή θεραπείας δημιουργώντας ένα προσωρινό άνοιγμα μέσα στο λιποκύτταρο για να επιτρέψει στα λιπαρά υγρά να στραγγισθούν έξω από το κύτταρο και να ξεπλυθούν φυσικά, μέσω του λεμφικού σας συστήματος, διότι τα λιποκύτταρα είναι απαραίτητα για την υγεία της καθημερινής λειτουργίας του σώματος.`,
                },
                {
                    title: 'Είναι Αποτελεσματικό;',
                    image: svgs_list.heart_ticked,
                    description: `Η μέθοδος αυτή συρρικνώνει τα λιπώδη κύτταρα αντί να τα σκοτώνει, καθιστώντας το ασφαλέστερο και πιο αποτελεσματικό σε σχέση με τις μεθόδους εξάλειψης λίπους όπως η λιποαναρρόφηση ή οι τεχνικές κατάψυξης λίπους.
                    Διαδικασίες που επικεντρώνονται στην εξάλειψη των περιοχών λίπους όπως η λιποαναρρόφηση ή οι τεχνικές κατάψυξης λίπους. Αυτό διότι πρόσφατη ιατρική έρευνα για το πώς λειτουργούν πραγματικά τα λιποκύτταρα, λέει ότι αυτή η προσέγγιση, μακροπρόθεσμα, όχι μόνο είναι αναποτελεσματική, αλλά θα μπορούσε να είναι και αντιπαραγωγική. Οι στατιστικές δείχνουν ότι η θανάτωση λιποκυττάρων μπορεί στην καλύτερη περίπτωση να προσφέρει προσωρινή βελτίωση στην εμφάνισή σας και μέσα στον πρώτο χρόνο, τα λιποκύτταρα θα αναγεννηθούν ή θα μεγαλώσουν σε νέες περιοχές του σώματός σας για να αντισταθμίσουν αυτό που καταστράφηκε. Αυτό μπορεί να έχει ως αποτέλεσμα τα σκοτωμένα λιπώδη κύτταρα να αναγεννηθούν έως και 300 δισεκατομμύρια μέσα σε 4‐5 χρόνια.`,
                },
            ],
            cards: [
                {
                    title: 'Στήθος',
                    image: svgs_list.male_chest,
                },
                {
                    title: 'Κοιλιακούς',
                    image: svgs_list.male_abs,
                },
                {
                    title: 'Μέση',
                    image: svgs_list.male_back,
                },
                {
                    title: 'Προγούλι | Λαιμό',
                    image: svgs_list.male_face,
                },
                {
                    title: 'Πλάτη',
                    image: svgs_list.human_full_back,
                },
                {
                    title: 'Βραχίωνες (Μπράτσα)',
                    image: svgs_list.male_arm,
                },
                {
                    title: 'Γλουτούς | Μηρούς | Γοφούς',
                    image: svgs_list.female_glutes,
                },
                {
                    title: 'Και άλλα...',
                },
            ],
            image: require('images/tools/MaxLipo Laser/maxlipo.png'),
            imagehash: 'UED+;[I:00-94nxa?HIU00xZ~COY-pkWNanO',
            image_original_size: { width: 1000, height: 960 },
            pointers: [
                {
                    x: '61.6%',
                    y: '60.8%',
                    size: '1.5%',
                    animation: 'continuous-palm',
                    trigger: true,
                },
            ],
            texts: [
                {
                    x: '22%',
                    y: '48.3%',
                    size: '2',
                    text: 'Μείωση λίπους',
                },
            ],
        },
    ];

    return (
        <Container className='fade-in-when-load' fluid>
            <PageDescription>
                Σε συνδιασμό με το EMS χρισημοποιούμε και άλλες τεχνολογίες
                ανάλογα με τις ανάγκες του κάθε πελάτη μας.
            </PageDescription>
            {techs.map((tech: any, index: any) => (
                <div key={index} style={{ marginBottom: '100px' }}>
                    <Row className='justify-content-center'>
                        <Col key={index} sm='auto' xs='auto'>
                            <div
                                className='text'
                                style={{
                                    fontSize: '3vmin',
                                    textAlign: 'center',
                                }}
                            >
                                {tech.name}
                            </div>
                            <BlurredImage
                                image={tech.image}
                                original_size={tech.image_original_size}
                                image_delay={0}
                                texts_delay={0.6}
                                blurhash_only_fade
                                blurHash={tech.imagehash}
                                alt={tech.image}
                                width='80vmin'
                                texts={tech.texts}
                                pointers={tech.pointers}
                            />
                            <BigText
                                align='left'
                                width='90vmin'
                                text={tech.description}
                            />
                        </Col>
                        {tech.description_cards && (
                            <Container>
                                <Row className='justify-content-center'>
                                    {tech.description_cards.map(
                                        (description_card: any, index: any) => (
                                            <Col
                                                className='mb-2 p-2'
                                                key={index}
                                                xs={
                                                    description_card.description
                                                        ? 12
                                                        : 6
                                                }
                                                sm={
                                                    description_card.description
                                                        ? 'auto'
                                                        : 6
                                                }
                                                md={
                                                    description_card.description
                                                        ? 'auto'
                                                        : 6
                                                }
                                                lg={
                                                    description_card.description
                                                        ? 4
                                                        : 3
                                                }
                                            >
                                                <Card
                                                    key={index}
                                                    title={
                                                        description_card.title
                                                    }
                                                    description={
                                                        description_card.description
                                                    }
                                                    image={
                                                        description_card.image
                                                    }
                                                    height='100%'
                                                />
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </Container>
                        )}
                    </Row>
                    {tech.cards && (
                        <Container>
                            <Row className='justify-content-center'>
                                {tech.cards.map((card: any, index: any) => (
                                    <Col
                                        className='mb-2 p-2'
                                        key={index}
                                        xs={card.description ? 12 : 6}
                                        sm={card.description ? 'auto' : 6}
                                        md={card.description ? 'auto' : 6}
                                        lg={card.description ? 4 : 3}
                                    >
                                        <Card
                                            key={index}
                                            title={card.title}
                                            description={card.description}
                                            image={card.image}
                                            height='100%'
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    )}
                </div>
            ))}
        </Container>
    );
}
