import React from 'react';
import { Container } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import BlurredImage from '../BlurredImage';

export default function EmsGroup() {
    const image = require('images/models/ems_group.png');

    const pointers = [
        {
            x: '41.8%',
            y: '28.5%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '25.7%',
            y: '44.8%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '55.8%',
            y: '26.1%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '72.2%',
            y: '53.6%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '20.6%',
            y: '66%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
    ];

    const texts = [
        {
            x: '7.5%',
            y: '13%',
            size: '2',
            text: '2 φορές την εβδομάδα',
        },
        {
            x: '2.5%',
            y: '25.7%',
            size: '2',
            text: '20 λεπτά \n η συνεδρία',
        },
        {
            x: '68%',
            y: '2.3%',
            size: '2',
            text: '20 λεπτά EMS = 4 ώρες κλασσικής γυμναστικής',
        },
        {
            x: '85%',
            y: '50%',
            size: '2',
            text: 'Ευεξία',
        },
        {
            x: '1.1%',
            y: '51.8%',
            size: '2',
            text: 'Χωρίς \n τραυματισμούς',
        },
    ];

    return (
        <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BlurredImage
                image={image}
                original_size={{ width: 912, height: 693 }}
                image_delay={0}
                texts_delay={0.6}
                blurhash_only_fade
                blurHash='q139S:x^00Mwt-kr%grqyDbbROofS4WBxuoe8wVs*0o#$LsSE1R-DOaKx]aK$%s:D*NHDiad-;t7IoM{s9t7?bozIVWBNbS4-nxZ'
                alt={image}
                width='80vmin'
                texts={texts}
                pointers={pointers}
            />
        </Container>
    );
}
