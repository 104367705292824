import React, { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useInView } from 'react-intersection-observer';
import ImageWithTexts from './ImageWithTexts';

export default function BlurredImage(props) {
    const { ref: myRef, inView: myElementIsVisible } = useInView();
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div
            style={{
                position: 'relative',
                display: 'block',
                opacity: `${!myElementIsVisible ? 0 : 1}`,
                transition: 'opacity 500ms',
            }}
            ref={myRef}
        >
            {(props.blurhash_only_fade || props.fade) && (
                <>
                <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: 'rgba(255, 255, 255, 0)',
                            background:
                                '-webkit-radial-gradient(circle, rgba(255, 255, 255, 0) 0%, #000000 100%)',
                            background:
                                'radial-gradient(circle, rgba(255, 255, 255, 0) 0%, #000000 100%)',
                                width: props.width,
                                height: props.original_size
                                    ? `calc(${props.width} / (${props.original_size.width}/${props.original_size.height}))`
                                    : props.height,
                            zIndex: 2,
                            transition: 'opacity 1000ms',
                            opacity: props.blurhash_only_fade ? `${isLoaded ? 0 : 1}` : `${props.fade && 1}`,
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: 'rgba(255, 255, 255, 0)',
                            background:
                                '-webkit-radial-gradient(circle, rgba(255, 255, 255, 0) 0%, #000000 100%)',
                            background:
                                'radial-gradient(circle, rgba(255, 255, 255, 0) 0%, #000000 100%)',
                                width: props.width,
                                height: props.original_size
                                    ? `calc(${props.width} / (${props.original_size.width}/${props.original_size.height}))`
                                    : props.height,
                            zIndex: 2,
                            transition: 'opacity 1000ms',
                            opacity: props.blurhash_only_fade ? `${isLoaded ? 0 : 1}` : `${props.fade && 1}`,
                        }}
                    />
                </> 
            )}
            <Blurhash
                hash={props.blurHash}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transition: 'opacity 500ms',
                    opacity: isLoaded ? 0 : 1,
                    width: props.width,
                    height: props.original_size
                        ? `calc(${props.width} / (${props.original_size.width}/${props.original_size.height}))`
                        : props.height,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transition: 'opacity 500ms',
                    opacity: isLoaded ? 1 : 0,
                }}
            >
                <ImageWithTexts
                    setIsLoaded={setIsLoaded}
                    isLoaded={isLoaded}
                    image_delay={props.image_delay}
                    texts_delay={props.texts_delay}
                    image={props.image}
                    width={props.width}
                    texts={props.texts || []}
                    pointers={props.pointers || []}
                />
            </div>
        </div>
    );
}
