import { Container } from 'react-bootstrap';
import BlurredImage from '../BlurredImage';
import 'styles/extras.scss';

export default function Benefits() {
    const ems_basic_suit = require('images/tools/ems_basic_suit1.png');

    const BasicEmsSuitPoints = [
        // make the rest of the points
        {
            x: '32.8%',
            y: '13.5%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '75%',
            y: '32%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '59.7%',
            y: '49.1%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '62.1%',
            y: '82.3%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '23.8%',
            y: '22.1%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
            zIndex: -1,
        },
        {
            x: '30.5%',
            y: '55.1%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
            zIndex: -1,
        },
        {
            x: '63.8%',
            y: '62.9%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: true,
            zIndex: -1,
        },
        {
            x: '26.7%',
            y: '74.6%',
            size: '2%',
            animation: 'continuous-palm',
            trigger: false,
            zIndex: -1,
        },
    ];

    const BasicEmsSuitTexts = [
        // make the rest of the texts
        {
            x: '55%',
            y: '4.5%',
            size: '2',
            text: 'Αντιγήρανση',
        },
        {
            x: '0%',
            y: '16.8%',
            size: '2',
            text: `Ελλάτωση \n πόνων πλάτης`,
        },
        {
            x: '90%',
            y: '21.3%',
            size: '2',
            text: `Μυϊκή \n ενδυνάμωση`,
        },
        {
            x: '7%',
            y: '51.5%',
            size: '2',
            text: `Ενίσχυση \n οσφυαλικής \n περιοχής`,
        },
        {
            x: '75%',
            y: '40.5%',
            size: '2',
            text: `Μείωση \n κοιλιακού λίπους`,
        },
        {
            x: '73.2%',
            y: '60.7%',
            size: '2',
            text: `Ανόρθωση γλουτών`,
        },
        {
            x: '5%',
            y: '66.4%',
            size: '2',
            text: `Καύση λίπους \n έως και 72 \n ώρες μετά`,
        },
        {
            x: '79%',
            y: '72%',
            size: '2',
            text: `Αντικυτταρίδα`,
        },
    ];

    return (
        <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BlurredImage
                image={ems_basic_suit}
                original_size={{ width: 560, height: 705 }}
                image_delay={0}
                texts_delay={0.6}
                blurhash_only_fade
                blurHash='B01:RZvf00qFm+kq'
                alt={ems_basic_suit}
                width='80vmin'
                texts={BasicEmsSuitTexts}
                pointers={BasicEmsSuitPoints}
            />
        </Container>
    );
}
