import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavbarMenu from 'components/assets/NavbarMenu';
import ElectroStimulation from 'pages/ElectroStimulation';
import Footer from 'components/assets/Footer';
import Logo from 'pages/Main/Logo';
import CommonQuestions from 'pages/CommonQuestions';
import Contact from 'pages/Contact';
import Technologies from 'pages/Technologies';

ReactDOM.render(
    <BrowserRouter>
    <NavbarMenu />
    <Logo />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/electrostimulation" element={<ElectroStimulation />} />
      <Route path="/common-questions" element={<CommonQuestions />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/technologies" element={<Technologies />} />
      <Route path="*" element={<h1>404: Not Found</h1>} />
    </Routes>
    <Footer />
  </BrowserRouter>,
  document.getElementById('root')
);