import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function ImageWithTexts(data) {
    function Text(props) {
        return (
            <div
                className='text no-select'
                style={{
                    position: 'absolute',
                    top: props.y,
                    left: props.x,
                    fontSize: `${props.size}vmin`,
                    zIndex: 3,
                }}
            >
                {props.text.split('\n').map((char, index) => {
                    return (
                        <p
                            className='fade-in-when-load'
                            key={index}
                            style={{
                                margin: 0,
                                animationDelay: `${
                                    index * 0.2 +
                                    (data.texts_delay || 0) +
                                    (data.image_delay || 0)
                                }s`,
                            }}
                        >
                            {char}
                        </p>
                    );
                })}
            </div>
        );
    }

    function Pointer(props) {
        const animations = {
            'basic-palm': {
                className: 'pulse-basic-palm',
                animation: 'pulse-c6zlskmn',
            },
            'continuous-palm': {
                className: 'pulse-continuous-palm',
                animation: 'pulse-kefe91md',
            },
        };

        return (
            <Tooltip
                className='fade-in-when-load'
                title={props.tooltip}
                placement='top'
                arrow
            >
                <div
                    className={`${
                        animations[props.animation].className
                    } fade-in-when-load`}
                    style={{
                        aspectRatio: '1/1',
                        width: `${props.size || '15px'}`,
                        zIndex: `${props.zIndex || 1}`,
                        position: 'absolute',
                        top: props.y,
                        left: props.x,
                        transition: 'opacity 15s',
                        opacity: props.trigger ? 1 : 0.5,
                        animation:
                            props.trigger &&
                            `${
                                animations[props.animation].animation
                            } 1.2s infinite`,
                        zIndex: `${props.zIndex || 3}`,
                    }}
                    // onMouseEnter={() => setShowCurrentpropsOnHover(props.id)}
                    // onMouseLeave={() => setShowCurrentpropsOnHover(null)}
                />
            </Tooltip>
        );
    }

    return (
        <div
            style={{
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <div
                style={{
                    width: data.width,
                }}
            >
                <img
                    className='fade-in-when-load'
                    onLoad={() => {
                        data.setIsLoaded(true);
                    }}
                    style={{
                        width: data.width,
                        position: 'relative',
                        animationDelay: `${data.image_delay || 0.5}s`,
                        zIndex: 1,
                        display: 'block',
                    }}
                    src={data.image}
                    alt={data.image}
                    width='100%'
                    height='100%'
                />
            </div>
            {/*  Points */}
            {data.pointers.map((point, index) => {
                return (
                    <Pointer
                        key={index}
                        x={point.x}
                        y={point.y}
                        size={point.size}
                        animation={point.animation}
                        trigger={point.trigger}
                        zIndex={point.zIndex}
                    />
                );
            })}
            {/*  Texts */}
            <div>
                {data.texts.map((text, index) => {
                    return (
                        <Text
                            key={index}
                            x={text.x}
                            y={text.y}
                            size={text.size}
                            text={text.text}
                            delay={1}
                        />
                    );
                })}
            </div>
        </div>
    );
}
