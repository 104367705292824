import React from 'react';
import { Container } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import BlurredImage from '../BlurredImage';
import 'styles/extras.scss';

export default function FitnessPrograms() {
    const image = require('images/models/man_strength_cardio_woman_fitness_body_final.png');

    const pointers = [
        {
            x: '32.4%',
            y: '29.2%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '68.3%',
            y: '38.4%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
        {
            x: '70.8%',
            y: '59.2%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        }
    ];

    const texts = [
        {
            x: '5.5%',
            y: '21.1%',
            size: '2',
            text: 'Ενδυνάμωση',
        },{
            x: '75.9%',
            y: '28.4%',
            size: '2',
            text: 'Μεταβωλισμός',
        },{
            x: '78%',
            y: '45%',
            size: '2',
            text: 'Σύσφιξη & \n Απώλεια λύπους',
        }
        
    ];

    return (
        <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BlurredImage
                image={image}
                original_size={{ width: 912, height: 696 }}
                image_delay={0}
                texts_delay={0.6}
                blurhash_only_fade
                blurHash='U73c;GbwHXn3*0j[Qla#QRn$pdS%MIf6t-bI'
                alt={image}
                width='80vmin'
                texts={texts}
                pointers={pointers}
            />
        </Container>
    );
}
