import Staff from 'components/assets/Staff';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Map from 'components/assets/Map';

const employees = [
    {
        name: 'Ανδρέας Χρυσοχέρης',
        workshop_admin: 1,
        roles: [
            { color: 'red', role: 'Συνιδρυτής' },
            { color: 'blue', role: 'Personal Trainer' },
        ],
        image: require('images/staff/andreas_chrysocheris.jpg'),
        image_hash: 'UwJj-*j]%Lt6~WWWbIj[RnkCM|WVo#j]f5WC',
        socials: [
            {
                name: 'linkedin',
                link: 'https://www.linkedin.com/in/andreas-chrysoheris-a30a6239/',
            },
        ],
    },
    {
        name: 'Δημήτριος Χρυσοχέρης',
        workshop_admin: 0,
        roles: [
            { color: 'red', role: 'Συνιδρυτής' },
            { color: 'blue', role: 'Personal Trainer' },
        ],
        image: require('images/staff/dimitris_chrysocheris.jpg'),
        image_hash: 'LIF$*L-U80OYNE9Z%2V??vxb#Qxt',
        socials: [
            {
                name: 'instagram',
                link: 'https://www.instagram.com/d1m.ch/',
            },
            {
                name: 'linkedin',
                link: 'https://www.linkedin.com/in/dimitris-chrysoheris-8bba1520a/',
            },
            // {
            //     name: 'portfolio',
            //     link: 'https://jimchr-r4gn4r.github.io/mybio/',
            // },
        ],
    },
    {
        name: 'Δέσποινα Χρυσοχέρη',
        roles: [{ color: 'red', role: 'Συνιδρυτής' }, { color: 'purple', role: 'Marketing' }],
        image: require('images/staff/despoina_chrysocheris.jpg'),
        image_hash: 'LyKJcLnh-oog}?ogxbRi%2R*Rjog',
        socials: [
            {
                name: 'instagram',
                link: 'https://www.facebook.com/dimitris.chrysocheris',
            },
        ],
    },
];

const workshops = [
    {
        id: 0,
        title: 'Ορφέως 44, Γαλάτσι, Αθήνα',
        googleMapLink: 'https://goo.gl/maps/UcLsUvqNVhKk5w798',
        x: '49%',
        y: '54%',
    },
    {
        id: 1,
        title: 'Μακαρίου 25, Άργος',
        googleMapLink: 'https://goo.gl/maps/Gq3HyiDxruDjwkpKA',
        x: '37%',
        y: '58%',
    },
];

export default function Contact() {
    return (
        <Container fluid>
            <Row className='fade-in-when-load justify-content-center'>
                <Map
                    country='greece'
                    workshops={workshops}
                    employees={employees}
                />
            </Row>
            <Row className='fade-in-when-load justify-content-center'>
                <Col sm='auto'>
                    <Staff employees={employees} />
                </Col>
            </Row>
        </Container>
    );
}
