import React from 'react';
import 'styles/app.scss';

export default function MyButton(props: any) {
    const [selected, setSelected] = React.useState(false);
    return (
        <div
            className={`${(selected || props.selected) && 'selected'} p-1 text`}
            style={{
                height: 'fit-content',
                width: 'fit-content',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
}
