import { Collapse } from '@nextui-org/react';

export default function MyCollapse(props: any) {
    return (
        <>
            <Collapse.Group
                css={{
                    '& .nextui-collapse-title': {
                        color: 'white',
                    },
                }}
                bordered
            >
                {props.collapses.map((collapse: any, index: any) => {
                    return (
                        <Collapse
                            bordered
                            key={index}
                            title={collapse.question}
                        >
                            <div className='text'>{collapse.answer}</div>
                        </Collapse>
                    );
                })}
            </Collapse.Group>
        </>
    );
}
