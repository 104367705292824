import Benefits from 'components/assets/Benefits'
import EmsCustomersAges from 'components/assets/EmsCustomersAges'
import EmsGroup from 'components/assets/EmsGroup'
import EmsMoreInfo from 'components/assets/EmsMoreInfo'
import FitnessPrograms from 'components/assets/FitnessPrograms'
import OtherEmsBenefits from 'components/assets/OtherEmsBenefits'
import { Row, Col } from 'react-bootstrap'

export default function wd() {
  return (
    <div>
      <Row className='justify-content-center mb-4'>
                <Col sm='auto'>
                    <Benefits />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col sm='auto'>
                    <EmsMoreInfo />
                </Col>
            </Row>
            <Row className='justify-content-center mb-4'>
                <Col sm='auto'>
                    <FitnessPrograms />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col sm='auto'>
                    <EmsGroup />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col sm='auto'>
                    <EmsCustomersAges />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col sm='auto'>
                    <OtherEmsBenefits />
                </Col>
            </Row>
    </div>
  )
}
