import BlurredImage from 'components/BlurredImage';
import MyCollapse from 'components/MyCollapse';
import TextImageBySide from 'components/TextImageBySide';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NextUIProvider, createTheme } from '@nextui-org/react';

export default function CommonQuestions() {
    const theme = createTheme({
        type: 'dark', // it could be "light" or "dark"
    });
    const quests_answers = [
        {
            question: 'Τί είναι το EMS;',
            answer: 'Το EMS (ηλεκτροδιέγερση) είναι μία μορφή φυσικής θεραπείας που χρησιμοποιεί ηλεκτρικούς παλμούς για να ενεργοποιήσει τους μΰες. Χρησιμοποιείται για τη θεραπεία διάφορων περιπτώσεων, συμπεριλαμβανομένων των χρόνιων πόνων, των μυοσπασμών και της μυικής αδυναμίας. Παράλληλα χρησιμοποιείτε για την ενδυνάμωση των μυών και την ενύσχηση του μεταβολισμού.',
        },
        {
            question: 'Υπάρχουν παρενέργειες με την άσκηση EMS;',
            answer: 'Εάν είστε υγιής, και χρησιμοποιείτε τη σωστή μεθοδολογία, δεν υπάρχουν επιβλαβείς παρενέργειες. Επαγγελματίες υγείας όπως οι φυσιοθεραπευτές και οι κινησιολόγοι το χρησιμοποιούν ως κινητική θεραπεία. Η μόνη παρενέργεια που υπάρχει περίπτωση να αντιμετωπίσετε με τη χρήση του, είναι μία απολύτως φυσιολογική παρενέργεια του σώματός σας, ως απάντηση στη μεγάλη μυϊκή ενεργοποίηση και είναι η αύξηση του cpk (κρεατινική κινάση), που δείχνει ότι πραγματικά γίνεται δουλειά στους μύες, όμως μπορεί να οδηγήσει σε υπερκόπωση. Κάτι που αποφεύγουμε, κάνοντας συνεδρίες ανά 2-3 ημέρες, ώστε οι μύες να έχουν χρόνο για ξεκούραση!',
        },
        {
            question: 'Σε πόσο διάστημα βλέπω αποτελέσματα;',
            answer: '«Μάγοι» είμαστε, αλλά θαύματα δεν κάνουμε! Η ευεξία είναι αποτέλεσμα ενός ισορροπημένου τρόπου ζωής. Χρειάζεται να προσαρμόσετε τις διατροφικές σας συνήθειες, να ξεκουράζεστε, να κοιμάστε και να ασκείστε με EMS με συνέπεια και πρόγραμμα. Αν είσαστε συνεπείς και διατηρείτε ένα πρόγραμμα τακτικής άσκησης και διατροφής, τα αποτελέσματα εμφανίζονται πολύ γρήγορα (από τις πρώτες 4 – 5 συνεδρίες). Αν όμως προπονείστε μία φορά στο τόσο και δεν ακολουθείτε σωστή διατροφή, τότε χρειάζεστε ένα θαύμα!',
        },
        {
            question: 'Πώς αισθάνομαι κατά την διάρκεια της άσκησης;',
            answer: 'Υπάρχει η αίσθηση ενός ευχάριστου “γαργαλήματος” λόγω της ταυτόχρονης σύσπασης περισσοτέρων από 350 μύες. Αντίστοιχα μετά την άσκηση ο εγκέφαλος απελευθερώνει ορμόνες όπως η ενδορφίνη, η ντοπαμίνη και η σερετόνη. Χαρη σε αυτές τις ορμόνες θα βρεθείτε σε εγρήγορση, πιο εστιασμένοι και συγκεντρωμένοι και με έμα αίσθημα ευφορίας.  Αντίθετα, μειώνονται οι ορμόνες που ευθύνονται για το στρες (κορτιζόλη).  Ορίστε, μόλις αποκτίσατε το κοκτέιλ της ευτυχίας!',
        },
        {
            question: 'Για ποιους ενδείκνυται η ηλεκτροδιέγερση;',
            answer: 'Η ηλεκτροδιέγερση μπορεί να χρησιμοποιηθεί από όλους.Ο τύπος της ηλεκτροδιέγερσης που εφαρμόζεται εξαρτάται πάντα από τις ανάγκες και τους στόχους του ασκούμενου, καθώς και από τη φυσική του κατάσταση ή τις υποκείμενες παθολογίες του, κάτι που αξιολογεί ο επαγγελματίας προπονητής και ο Ιατρός.',
        },
        {
            question: 'Είναι ασφαλής μέθοδος;',
            answer: 'Πρέπει να γνωρίζετε ότι το ερέθισμα που χρησιμοποιείται για τη συστολή των μυών είναι ένα ηλεκτρικό ρεύμα. Είναι είτε ακούσιο (το σώμα μας δίνει την εντολή), είτε εθελοντικό (εξοπλισμός άσκησης EMS). Ο εγκέφαλος, στέλνει ένα μήνυμα στους μύες, μέσω του κεντρικού νευρικού συστήματος, για μυϊκή ενεργοποίηση. Η προπονητική συσκευή EMS, στέλνει μια εντολή στους κινητικούς νευρώνες για μυϊκή ενεργοποίηση. Επομένως είναι ασφαλής και φυσική μέθοδος.',
        },
        {
            question: 'Σε ποιους τομείς χρησιμοποιείται η ηλεκτροδιέγερση;',
            answer: 'Στα Σπορ, τη Γυμναστική, τη Φυσιοθεραπεία, την Αισθητική  κ.α',
        },
    ];
    return (
        <Container
            fluid
            className='fade-in-when-load '
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <MyCollapse collapses={[...quests_answers]} />
        </Container>
    );
}
