import ImageFader from 'components/ImageFader';

export default function Logo() {
    const backmodel = require('images/BackModel_final.png');
    const logo = require('images/logo.png');

    return (
        <div
            className='fade-in-when-load no-select'
            style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
            }}
        >
            <ImageFader
                        image1={logo}
                        image2={backmodel}
                        text='2/20 EMS WELLNESS ROUTE'
                        delay={1500}
                    />
        </div>
    );
}
