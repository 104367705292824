import React, { useState } from 'react';

export default function ImageFader(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    setTimeout(() => {
        isLoaded &&
        setCurrentImage(1);
    }, props.delay);

    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <div
                className='fade-in-when-load'
                style={{
                    transform: 'translate(-50%, -50%)',
                    opacity: currentImage === 0 ? 1 : 0,
                    transition: 'opacity 1000ms',
                }}
            >
                <img
                    onLoad={() => setIsLoaded(true)}
                    style={{
                        display: 'block',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        height: '250px',
                        transform: 'translate(-50%, -50%)',
                        opacity: currentImage === 0 ? 1 : 0,
                        transition: 'opacity 1000ms',
                        ...props.style1,
                    }}
                    src={props.image1}
                    alt='image1'
                />
            </div>
            <div
                style={{
                    transform: 'translate(-50%, -50%)',
                    opacity: currentImage === 1 ? 1 : 0,
                    transition: 'opacity 1000ms',
                }}
            >
                <img
                    src={props.image2}
                    alt='image2'
                    style={{
                        position: 'absolute',
                        display: 'block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: `${currentImage === 1 ? 1 : 0}`,
                        transition: 'opacity 1000ms',
                        overflow: 'hidden',
                        ...props.style2
                    }}
                />
                {props.text && <div
                    className='no-select'
                    style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        top: '-30px',
                        width: 'fit-content',
                        transform: 'translate(-50%, -50%)',
                        fontFamily: 'Melete',
                        color: 'white',
                        fontSize: '2.5vw',
                        zIndex: 0,
                        animation: 'fadein 1s',
                        whiteSpace: 'nowrap',
                        opacity: `${currentImage === 1 ? 1 : 0}`,
                        transition: 'opacity 1000ms',
                    }}
                >
                    {props.text}
                </div>}
            </div>
        </div>
    );
}
