import React from 'react'
import { Container } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import BlurredImage from '../BlurredImage';

export default function EmsMoreInfo() {
    const image = require('images/models/man_with_ems_device_final1.png');

    const pointers = [
        {
            x: '57.3%',
            y: '65.8%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },{
            x: '32.7%',
            y: '23%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
    ];

    const texts = [
        {
            x: '65.5%',
            y: '49.1%',
            size: '2',
            text: 'Νο1 κατασκευαστής \n μηχανημάτων EMS 3ης γενιάς',
        },{
            x: '1.7%',
            y: '3.2%',
            size: '2',
            text: 'Γυμνάζοντας \n τουλάχιστον 350 μύες',
        },
        
    ];

    return (
        <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BlurredImage
                image={image}
                original_size={{ width: 912, height: 693 }}
                image_delay={0}
                texts_delay={0.6}
                blurhash_only_fade
                blurHash='U00cyDlAHXVDlAk=kAf5Q+bvuPpJozg3XAf+'
                alt={image}
                width='80vmin'
                texts={texts}
                pointers={pointers}
            />
        </Container>
    );
}
