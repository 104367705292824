import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import EmsIntro from 'pages/Main/EmsIntro';
import { Collapse, Button } from 'react-bootstrap';
import 'styles/app.scss';
import MyCollapse from 'components/MyCollapse';

export const logo = require('images/logo.png');

function App() {
    return (
        <Container fluid>
            <Row>
                <EmsIntro />
            </Row>
        </Container>
    );
}

export default App;
