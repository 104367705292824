import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'styles/app.scss';
import { logo } from 'App';
import ImageFader from 'components/ImageFader';
import { useInView } from 'react-intersection-observer';

export default function Footer() {
    const { ref: myRef, inView: myElementIsVisible } = useInView();
    const [isShown, setIsShown] = React.useState(false); // fade only the first time you see footer
    const hellas_ems_revolution_center = require('images/others/ems_revolution_hellas_center_logo.png');
    const ems_revolution = require('images/others/ems_revolution_logo.png');
    const gov_certs = require('images/others/Certification Organisms/gov_certs.png');

    React.useEffect(() => {
        if (myElementIsVisible) {
            setIsShown(true);
        }
    }, [myElementIsVisible]);

    return (
        <Container
            fluid
            ref={myRef}
            className={`${
                (myElementIsVisible || isShown) ? 'fade-in-when-load' : ''
            } mt-4 mb-2 no-select`}
        >
            <Row>
                <Col md={{ span: 2, offset: 2 }}>
                    <div
                        style={{
                            height: '150px',
                            width: '150px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                        }}
                    >
                        {(myElementIsVisible || isShown) && (
                            <ImageFader
                                image1={ems_revolution}
                                image2={hellas_ems_revolution_center}
                                style1={{
                                    height: '150px',
                                    position: 'absolute',
                                }}
                                style2={{
                                    height: '150px',
                                    position: 'absolute',
                                }}
                                delay={1500}
                            />
                        )}
                    </div>
                </Col>
                <Col md={{ span: 4, offset: 2 }}>
                    <img
                        
                        style={{
                            display: 'block',
                            margin: 'auto',
                            paddingTop: '20px',
                        }}
                        src={gov_certs}
                        alt='gov_certs'
                        height={95}
                        width='100%'
                    />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col>
                    <div
                        className='text'
                        style={{
                            width: 'fit-content',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                        }}
                    >
                        MEDI-EMS SCIENCE - All rights reserved © 2022
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
