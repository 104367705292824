import React from 'react';
import { Blurhash } from 'react-blurhash';
import 'styles/staff.scss';

export default function BlurStaffHashImage(props: any) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const beforeLoad = { opacity: '0' };
    const afterLoad = {
        opacity: '1',
        transition: `opacity ${props.seconds || '1'}s ${
            props.delay || ''
        } ease-out`,
    };

    const blur_beforeLoad = { opacity: '1' };
    const blur_afterLoad = {
        opacity: '0',
        transition: `opacity ${props.seconds || '1'}s ${
            props.delay || ''
        } ease-out`,
    };

    return (
        <div
            style={{
                position: 'relative',
                width: props.width || '150px',
                height: '150px',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                <img
                    className='card__card-image'
                    src={props.image}
                    onLoad={() => setIsLoaded(true)}
                    style={isLoaded ? afterLoad : beforeLoad}
                    width={props.width}
                    height={props.height}
                    alt={props.alt}
                />
            </div>
            { props.hash &&
                <Blurhash
                    className='card__card-image'
                    style={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        ...(isLoaded ? blur_afterLoad : blur_beforeLoad),
                    }}
                    hash={props.hash}
                    width={props.width}
                    height={props.height}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                />
            }
        </div>
    );
}
