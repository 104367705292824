import React from 'react';
import { Container } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import BlurredImage from '../BlurredImage';

export default function EmsGroup() {
    const image = require('images/models/oldman_newman.png');

    const pointers = [
        {
            x: '24.4%',
            y: '26.8%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },{
            x: '76.4%',
            y: '29%',
            size: '1.5%',
            animation: 'continuous-palm',
            trigger: true,
        },
    ];

    const texts = [
        {
            x: '7.5%',
            y: '8.5%',
            size: '2',
            text: 'Από 15',
        },{
            x: '82.9%',
            y: '14%',
            size: '2',
            text: 'έως 70+ ετών',
        },
    ];

    return (
        <Container
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BlurredImage
                image={image}
                original_size={{ width: 800, height: 449 }}
                image_delay={0}
                texts_delay={0.6}
                blurhash_only_fade
                blurHash='V25E{WIo01s:00~pR*E2af9Z8wt8-:RjOs9GWC?Gt7Ip'
                alt={image}
                width='80vmin'
                texts={texts}
                pointers={pointers}
            />
        </Container>
    );
}
