import React from 'react';
import { Container } from 'react-bootstrap';

export default function PageDescription(props: any) {
    return (
        <Container
            fluid
            className='text no-select'
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2.5vmin',
                textAlign: 'center',
                marginBottom: '150px',
            }}
        >
            {props.children}
        </Container>
    );
}
